import { useState } from "react";
import "./App.css";

import AddGreeting from "./components/AddGreeting";

function App() {
  // state-management
  const [greetings, setGreetings] = useState([]);

  const addGreeting = (
    firstName,
    lastName,
    greetingMessage,
    contribute,
    contributeMoney
  ) => {
    setGreetings([
      ...greetings,
      {
        firstName: firstName,
        lastName: lastName,
        greetingMessage: greetingMessage,
      },
    ]);
    console.debug(greetings);
    fetch("https://worker-old-mountain-dcf8.cwe.workers.dev", {
      mode: "no-cors",
      method: "POST",
      body: JSON.stringify({
        firstName: firstName,
        lastName: lastName,
        greetingMessage: greetingMessage,
        contribute: contribute,
        contributeMoney: contributeMoney,
      }),
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Content-type": "application/json; charset=UTF-8",
      },
    });

    // .then((response) => response.json())
    // .then((data) => {
    //   setGreetings((prevGreetings) => [data, ...prevGreetings]);
    // });
  };

  return (
    <>
      <div className="App">
        <h1>Alina Abschiedsgeschenk</h1>
        <div
          className="App-header"
          style={{
            textAlign: "center",
          }}
        >
          <img
            src="buch-1.png"
            alt="Basiswissen für Software-Architekten"
            style={{ padding: "10px" }}
          />
          <img
            src="buch-2.png"
            alt="Ethik in KI und Robotik"
            style={{ padding: "10px" }}
          />
          <img
            src="buch-3.png"
            alt="Standardwerk Künstliche Intelligenz"
            style={{ padding: "10px" }}
          />
        </div>
        <p />
        <header className="App-header">
          <AddGreeting addGreeting={addGreeting} />
        </header>
      </div>
    </>
  );
}

export default App;
