// Step 0: Import React
import { useState } from "react";

// Step 1: Define Component
const AddGreeting = (props) => {
  // state-management
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [greetingMessage, setGreetingMessage] = useState("");
  const [greetingMessageCount, setGreetingMessageCount] = useState(0);
  const [contribute, setContribute] = useState(false);
  const [contributeMoney, setContributeMoney] = useState(10);

  const checkIfValid = () => {
    return firstName.length && lastName.length && greetingMessage.length;
  };

  // save-function
  const handleSubmit = (e) => {
    e.preventDefault();
    props.addGreeting(
      firstName,
      lastName,
      greetingMessage,
      contribute,
      contributeMoney
    );

    // reset state-management
    setFirstName("");
    setLastName("");
    setGreetingMessage("");
    setContribute(false);
    setContributeMoney(10);
    setGreetingMessageCount(0);

    alert(
      "Vielen Dank für deine Abschiedsnachricht an Alina! Dies wurde erfolgreich gespeichert."
    );
  };

  return (
    <form onSubmit={handleSubmit}>
      <h2>Alina eine Nachricht mitgeben</h2>
      <div className="input-container">
        <label htmlFor="firstName">Vorname:</label>
        <input
          name="firstName"
          type="text"
          maxLength="20"
          value={firstName}
          onChange={(e) => setFirstName(e.target.value)}
        />
      </div>
      <div className="input-container">
        <label htmlFor="lastName">Nachname:</label>
        <input
          name="lastName"
          maxLength="20"
          type="text"
          value={lastName}
          onChange={(e) => setLastName(e.target.value)}
        />
      </div>
      <div className="input-container">
        <label htmlFor="greetingMessage">Abschiedsnachricht:</label>
        <span>{greetingMessageCount}/250</span>
      </div>
      <div className="input-container">
        <textarea
          name="greetingMessage"
          maxLength="250"
          type="text"
          value={greetingMessage}
          onChange={(e) => {
            setGreetingMessage(e.target.value);
            setGreetingMessageCount(e.target.value.length);
          }}
        />
      </div>
      <div className="input-container">
        <label htmlFor="contribute">Geschenkbeteiligung</label>
        <input
          name="contribute"
          type="checkbox"
          value={contribute}
          checked={contribute}
          onChange={(e) => setContribute(e.target.checked)}
        />

        <input
          name="contributeMoney"
          type="number"
          disabled={!contribute}
          value={contributeMoney}
          min="5"
          max="100"
          onChange={(e) => setContributeMoney(e.target.value)}
        />
      </div>
      <div className="input-container">
        <button type="submit" className="btn-submit" disabled={!checkIfValid()}>
          Speichern
        </button>
      </div>
    </form>
  );
};

// Step 2: Export Component
export default AddGreeting;
